<template>
    <h1>FX Filter</h1>
    <br>
    <CContainer>
        <CRow>
            <CCol :md="3">
                <input type="date" class="form-control" v-model="fxDate" />
            </CCol>
        </CRow>
        <CRow><br></CRow>
        <CCard>
            <CCardBody>
                <CRow>
                    <CCol :md="12">
                        <CRow style="border-bottom: 4px black solid;padding: 5px;">
                            <CCol :md="1">
                                Eventid
                            </CCol>
                            <CCol :md="4">
                                Match
                            </CCol>
                            <CCol :md="3">
                                Lega
                            </CCol>
                            <CCol :md="1">
                                Ora
                            </CCol>
                            <CCol :md="2">
                                Scores
                            </CCol>
                            <CCol :md="1">
                                Azione
                            </CCol>
                        </CRow>
                        <CRow v-for="(game, indG) in fxGames" style="border-bottom: 1px black solid;padding: 5px;">
                            <CCol :md="1">
                                {{ game.eventid }}
                            </CCol>
                            <CCol :md="4">
                                {{ game.match }}
                            </CCol>
                            <CCol :md="3">
                                {{ game.league[0] }}
                            </CCol>
                            <CCol :md="1">
                                {{ game.time }}
                            </CCol>
                            <CCol :md="2" v-if="game['res']">
                                <p v-if="Object.keys(game['res']).includes('updated')">FT: {{ game['res']['updated']['scores']['2']['home'] + ":" + game['res']['updated']['scores']['2']['away'] }}<br>HT: {{ Object.keys(game['res']['updated']['scores']).length > 1 ? game['res']['updated']['scores']['1']['home'] + ":" + game['res']['updated']['scores']['1']['away'] : ""}}</p>
                            </CCol>
                            <CCol :md="2" v-else>
                            </CCol>
                            <CCol :md="1">
                                <CButton href="#" @click="clickHandle(game)" color="danger" shape="rounded-pill">
                                    Elimina
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

    </CContainer>
</template>

<script>
import { ref, watch } from 'vue-demi';
import { useFXStore } from '../../store/fx_store';

export default {
    name:'FXfilter',
    setup() {
        const fxDate = ref(new Date());
        const store = useFXStore();
        const fxGames = ref([]);
        const removedGame = ref(false);

        watch(fxDate, (val) => {
            const dateToValidate = val.split('-');
            const dateToSend = dateToValidate[2]+'-'+dateToValidate[1]+'-'+dateToValidate[0];
            store.setDate(dateToSend);
            store.getGames.then(data => {
                fxGames.value = data.data;
            });
        })

        watch(removedGame, (val) => {
            if (val) {
                store.getGames.then(data => {
                    fxGames.value = data.data;
                });
                removedGame.value = false;
            }
        })

        const clickHandle = (game) => {
            store.removeGame(game.eventid).then(response => {
                if (response.data == 200) {
                    const index = fxGames.value.indexOf(game);
                    if (index != -1) {
                        fxGames.value.splice(index, 1);
                    }
                }
            });
        }

        return {
            fxDate,
            fxGames,
            clickHandle
        }
    }
}
</script>

<style>
</style>